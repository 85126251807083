import { Box, makeStyles } from "@material-ui/core";
import * as React from "react";
import topBanner from "../images/main_banner.svg";
import bannerImg from "../images/banner_charts.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  blueGrey,
  deepOrange,
  grey,
  indigo,
  orange,
} from "@material-ui/core/colors";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
import scnsht1 from "../images/perform_scnsht.png";
import inventory from "../images/inventory_main.jpg";
import SEO from "../components/seo";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  textButton: {
    color: deepOrange[800],
    userSelect: "none",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  subheader: {
    fontWeight: 700,
    textTransform: "uppercase",
    color: blueGrey[800],
    marginBottom: "12px",
  },
  outlineButton: {
    border: `1px solid #FFFFFF`,
    fontSize: "1em",
    userSelect: "none",
    cursor: "pointer",
    display: "inline-block",
    textTransform: "none",
    padding: "4px 12px",
    borderRadius: "20px",
    fontWeight: "450",
    color: deepOrange[600],
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: deepOrange[800],
    },
  },
}));

// markup
export default () => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery("(min-width:768px)");

  return (
    <Box bgcolor="#FFFFFF">
      <SEO
        title="Electronic Lab Notebook"
        description="LabLog Electronic Lab Notebook can help anyone improve laboratory processes. Collaborate and keep on eye on the big picture while keeping data and files secure."
      />
      <Helmet>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "MobileApplication",
                "name": "LabLog",
                "operatingSystem": "ANDROID",
                "applicationCategory": "https://schema.org/Productivity",
                "applicationSubCategory": "Electronic Lab Notebook",
                "url": "https://play.google.com/store/apps/details?id=com.Aiderbotics.Lab_Log&hl=en_US",
                "screenshot": "https://lh3.googleusercontent.com/E4M_0J4kIw6AcujkGSAtfJSy8wV1-77oUPkd4frqqrQNxHGuEwJVDEdTO2c0tZ36UBCC=w2748-h1908",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "5",
                  "ratingCount": "2"
                },
                "offers": {
                  "@type": "Offer",
                  "price": "39.99",
                  "priceCurrency": "USD"
                }
              }
            `}
        </script>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "MobileApplication",
                "name": "LabLog",
                "operatingSystem": "iOS",
                "applicationCategory": "https://schema.org/Productivity",
                "applicationSubCategory": "Electronic Lab Notebook",
                "url": "https://apps.apple.com/us/app/lablog/id1376329880",
                "screenshot": "https://is5-ssl.mzstatic.com/image/thumb/Purple123/v4/06/3d/fd/063dfdf2-3281-7f83-58dd-8af5dca726fb/pr_source.jpg/690x0w.jpg",
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "5",
                  "ratingCount": "3"
                },
                "offers": {
                  "@type": "Offer",
                  "price": "39.99",
                  "priceCurrency": "USD"
                }
              }
            `}
        </script>

        <script type="application/ld+json">
          {`
              {
               rum.start("7bd9beebef70be58b69a8dc6d9cfa458");
              }
            `}
        </script>
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "WebApplication",
                "name": "LabLog",
                "operatingSystem": "All",
                "applicationCategory": "https://schema.org/Productivity",
                "applicationSubCategory": "Electronic Lab Notebook",
                "url": "https://labnotebook.app/lablog",
                "offers": {
                  "@type": "Offer",
                  "price": "39.99",
                  "priceCurrency": "USD"
                }
              }
            `}
        </script>

        <script type="application/ld+json">
          {`
                {
                  "@context" : "http://schema.org",
                  "@type" : "LocalBusiness",
                  "name" : "LabLog",
                  "image" : "https://d33wubrfki0l68.cloudfront.net/89999619d0ad14b8358b11823a6effdc1bac25aa/384b5/static/banner-image-01-a22702fe476944c3cae2ae1d53cab6cd.png",
                  "telephone" : "833-452-2564",
                  "email" : "help@lablogapp.com",
                  "address" : {
                    "@type" : "PostalAddress",
                    "streetAddress" : "1201 Seven Locks Road",
                    "addressLocality" : "Rockville",
                    "addressRegion" : "MD",
                    "addressCountry": "USA",
                    "postalCode" : "20854"
                  }
                }
              `}
        </script>
      </Helmet>
      <Box
        overflow="hidden"
        style={{
          backgroundColor: indigo[100],
          backgroundImage: `url(${bannerImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
          backgroundSize: "1050px 500px",
        }}
      >
        <Box height="300px" position="relative">
          <Box height="300px" overflow="hidden">
            <img src={topBanner} height="300px" alt="Data Graphs" />
          </Box>
          <Box
            style={{ userSelect: "none" }}
            position="absolute"
            top="0"
            pl={2}
          >
            <Box
              fontSize="2.3em"
              fontFamily="Roboto"
              fontWeight="500"
              color="#FFFFFF"
            >
              <Box maxWidth="500px">
                We keep your lab data, files &amp; notes organized — so you can
                focus on what matters most.
              </Box>
            </Box>
            <Box
              color="#FFFFFF"
              maxWidth="480px"
              fontSize="1.2em"
              mt={1}
              fontWeight="300"
            >
              Empower your team to optimize laboratory processes from anywhere
            </Box>
            <Link to="/request-demo">
              <Box className={classes.outlineButton} mt={1.5} bgcolor="#FFFFFF">
                Request a demo
              </Box>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          textAlign="center"
          mt={2.5}
          fontSize="1.7em"
          fontWeight="500"
          component="h1"
        >
          Meet LabLog's Electronic Lab Notebook platform.
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          pl={2}
          mt={3.5}
          pr={2}
          justifyContent="center"
        >
          <Fade bottom delay={150}>
            <Box
              flexShrink={0}
              maxWidth="400px"
              bgcolor={grey[50]}
              p={1}
              pt={7}
              mr={1}
              mb={1}
            >
              <Box className={classes.subheader}>EASE OF USE</Box>
              <Box fontSize="0.95em" lineHeight="1.5em">
                LabLog is designed to be intuitive. Streamlined laboratory data
                and document management should be about becoming more efficient
                and precise - not about learning software. Easily record notes,
                upload files, log samples, and export data trends in one simple
                ELN platform.
              </Box>
            </Box>
          </Fade>
          <Fade bottom delay={250}>
            <Box
              flexShrink={0}
              maxWidth="400px"
              bgcolor={grey[100]}
              p={1}
              pt={7}
              mr={1}
              mb={1}
            >
              <Box className={classes.subheader}>Integrated Platform</Box>
              <Box fontSize="0.95em" lineHeight="1.5em">
                We are a Microsoft partner leveraging the OpenAPI initiative.
                Deploy LabLog in the cloud or on-premises and integrate with the
                tools you already use in your organization. From Microsoft
                Office and Teams to business intelligence platforms,
                integrations are available out of the box and as customizable.
              </Box>
            </Box>
          </Fade>
          <Fade bottom delay={350}>
            <Box
              flexShrink={0}
              maxWidth="400px"
              bgcolor={blueGrey[50]}
              p={1}
              pt={7}
              mr={1}
              mb={1}
            >
              <Box className={classes.subheader}>Trusted Leader</Box>
              <Box fontSize="0.95em" lineHeight="1.5em">
                We take data security seriously here at LabLog, and we are proud
                to exceed the industry standard when it comes to protecting your
                organization. Title 21 CFR Part 11 establishes the US FDA
                regulations for electronic records. LabLog has been
                independently verified to be{" "}
                {<Link to="/fda">Part 11 compliant</Link>}.
              </Box>
            </Box>
          </Fade>
          <Fade bottom delay={450}>
            <Box
              flexShrink={0}
              maxWidth="400px"
              bgcolor={indigo[50]}
              p={1}
              pt={7}
              mr={1}
              mb={1}
            >
              <Box className={classes.subheader}>
                Data &amp; Sample Tracking
              </Box>

              <Box fontSize="0.95em" lineHeight="1.5em">
                Add custom data types to your notes for easy meta analysis and
                trend reporting. LabLog links all lab documents and information
                across data points, files and lab samples for more streamlined
                lab work in collaborative environments. Use barcode printers and
                scanners to seamless inventory tracking.
              </Box>
            </Box>
          </Fade>
        </Box>
        <Box display="flex" p={3} mt={5} justifyContent="center">
          <Box maxWidth="300px">
            <Box width="30px" height="5px" bgcolor={deepOrange[400]} />
            <Box
              fontSize={mediumScreen ? "1.5em" : "0.9em"}
              component="h2"
              flexShrink={0}
              width={mediumScreen ? "200px" : "100px"}
            >
              Electronic Lab Notebook for everyone
            </Box>
          </Box>
          <Box width="40px" />
          <Box>
            <Box mt={2.5} mb={1} fontWeight={500}>
              For Lab supervisors
            </Box>
            <Box>
              Reduce collaboration friction and eliminate waste by tapping into
              LabLog ELN modules designed to make lab work more efficient.
            </Box>
            <Box>
              From file sharing to e-signatures, LabLog empowers you to give
              multiple teams in your organization the tools they need to work
              together more efficiently.
            </Box>
            <Box></Box>
            <Box mt={2.5} mb={1} fontWeight={500}>
              For Project managers
            </Box>
            <Box>
              Managing a robust product development pipeline requires
              collaborative effort.
            </Box>
            <Box>
              LabLog gives you the tools you need to analyze team performance,
              collaborate, and manage new projects.
            </Box>
            <Box mt={2.5} mb={1} fontWeight={500}>
              For Laboratory researchers
            </Box>
            <Box>
              Lab work is a dynamic and fast paced effort. Organize, track, and
              share your methods, experiments and data with a touch of a button.
            </Box>
            <Box>
              Gain powerful insights into your weekly and monthly progress as
              new experiments become necessary.
            </Box>
          </Box>
        </Box>
        <Fade up>
          <Box p={1} pt={8} pb={8} width="100%" bgcolor={grey[100]}>
            <Box width={mediumScreen ? "650px" : "100%"} margin="auto">
              <Box component="h2" textAlign="center">
                See data trends and team performance in a single platform
              </Box>

              <Box width="100%" margin="auto">
                <img
                  src={scnsht1}
                  alt="Team analytics reporting"
                  style={{ objectFit: "scale-down" }}
                  width="100%"
                />
              </Box>
              <Box component="p" mt={3} textAlign="center" width="100%">
                As a Microsoft partner we give you the tools you need to work
                smarter, make discoveries faster, and report outcomes
                seamlessly. Leverage our team of expert developers and project
                managers to deploy a customized solution that already knows how
                to talk to other Microsoft tools in your organization.
              </Box>
            </Box>
          </Box>
        </Fade>
        <Box display="flex" p={3} mt={5} justifyContent="center">
          <Box width="300px">
            <Box width="30px" height="5px" bgcolor={deepOrange[400]} />
            <Box fontSize="1.5em" component="h2">
              On-site Onboarding &amp; Training
            </Box>
          </Box>
          <Box width="40px" />
          <Box width="500px">
            Embracing a new Lab Notebook platform (ELN) with a fast adoption
            cycle can create logistical challenges. You can request on-site
            rollout, tech support and training for your new Electronic Lab
            Notebook software. Our experts will ensure that your labs are up and
            running in no time while meeting all relevant regulatory
            requirements. Enjoy a seamless transition to LabLog ELN platform
            with the help of our experts. Our head office is based in Maryland,
            USA, and our office is located near the FDA and NIH campuses.
          </Box>
        </Box>
        <Box display="flex" justifyContent="center" pt={8} pb={8}>
          <Box>
            <Box
              maxWidth="300px"
              display="block"
              margin="auto"
              height="1px"
              mb={2}
              bgcolor={blueGrey[100]}
            />
            <Box component="h2" textAlign="center">
              Keeping your lab data and findings secure
            </Box>
            <Box
              component="p"
              fontSize="1.2em"
              mt={3}
              textAlign="center"
              maxWidth="600px"
            >
              IT security is critical for electronic lab notebooks (ELN). We
              adhere to federal and international standards for cybersecurity
              and make our third-party verified audit reports available to our
              clients. Learn more about{" "}
              <Link to="/app-security">ELN data security</Link>.
            </Box>
            <Box
              width="300px"
              display="block"
              margin="auto"
              height="1px"
              mb={2}
              bgcolor={blueGrey[100]}
            />
          </Box>
        </Box>
        <Fade up>
          <Box
            display="flex"
            justifyContent="center"
            pt={8}
            pb={8}
            bgcolor={grey[100]}
          >
            <Box>
              <Box component="h2" fontSize="2em" textAlign="center">
                LabLog helps you succeed
              </Box>
              <Box
                mt={3}
                display="block"
                margin="auto"
                textAlign="center"
                maxWidth="600px"
              >
                Use LabLog’s ELN modules to plan lab work, automate workflows,
                design standard forms, comply with FDA Part 11, manage lab
                inventory and more.
              </Box>
              <Box
                display={mediumScreen ? "flex" : ""}
                mt={3}
                justifyContent="space-between"
              >
                <Box>
                  <img
                    src={inventory}
                    width={mediumScreen ? "500px" : "100%"}
                    alt="Scientist scanning inventory samples"
                  />
                </Box>
                <Box ml={2} maxWidth="350px">
                  <Box component="h3">
                    Track data, documents, files, and inventory samples in one
                    simple ELN platform.
                  </Box>
                  <Box component="p" mt={1} maxWidth="350px">
                    LabLog's modular design provides powerful out of the box
                    features for complete laboratory workflow management.
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
        <Box p={6} pt={9} pb={9}>
          <Box fontSize="2.5em" fontWeight="700" mt={1}>
            See LabLog in action
          </Box>
          <Box fontSize="1em" mt={1} mb={1}>
            Schedule a live demo for your team to quickly evaluate features and
            have your sales questions answered.
          </Box>
          <Link to="/request-demo" className={classes.textButton}>
            <Box
              mt={4}
              style={{
                borderRadius: "20px",
                padding: "6px",
                backgroundColor: deepOrange[600],
                color: "#FFFFFF",
                display: "inline-block",
              }}
            >
              Schedule Demo
            </Box>
          </Link>

          <Link to="/compare">
            <Box
              mt={4}
              ml={4}
              style={{
                borderRadius: "20px",
                padding: "6px",
                border: `1px solid ${deepOrange[600]}`,
                color: deepOrange[600],
                display: "inline-block",
              }}
            >
              Compare us
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
